import { IPlugin } from "../types";
import { IPluginForm } from "../types/plugins";

const getSource = (s: unknown) =>
  typeof s === "string" && (s === "ldjson" || s === "opengraph")
    ? s
    : undefined;

const isSources = (s: IPluginForm["sources"]) => {
  let found = false;
  found = !!s?.currencyCode;
  found = found || !!s?.currency;
  found = found || !!s?.image;
  found = found || !!s?.price;
  found = found || !!s?.title;
  return found;
};

const normalizePlugin = ({
  targetUrl: _t,
  proxyGeoCountryCode: _p,
  ...options
}: IPluginForm): IPlugin => {
  return {
    ...options,
    proxy: options.proxy || undefined,
    imageProxy: options.imageProxy || undefined,
    allowJquery: options.allowJquery || undefined,
    sources: isSources(options.sources)
      ? {
          currency: getSource(options.sources.currency),
          currencyCode: getSource(options.sources.currencyCode),
          image: getSource(options.sources.image),
          price: getSource(options.sources.price),
          title: getSource(options.sources.title),
        }
      : undefined,
    page: options.page
      ? {
          userAgent: options.page?.userAgent || undefined,
          waitForSelector: options.page?.waitForSelector || undefined,
          waitForTimeout: options.page?.waitForTimeout || undefined,
          waitUntil: options.page?.waitUntil || undefined,
        }
      : undefined,
    domSelectors: options.domSelectors
      ? {
          id:
            options.domSelectors.id?.dom || options.domSelectors.id?.key
              ? {
                  dom: options.domSelectors.id.dom || undefined,
                  key: options.domSelectors.id.key || undefined,
                }
              : undefined,
          brand: options.domSelectors.brand?.dom
            ? options.domSelectors.brand
            : undefined,
          images:
            options.domSelectors.images?.dom ||
            options.domSelectors.images?.heightParam?.value ||
            options.domSelectors.images?.widthParam?.value
              ? {
                  dom: options.domSelectors.images.dom || undefined,
                  heightParam: options.domSelectors.images.heightParam?.value
                    ? {
                        name:
                          options.domSelectors.images.heightParam.name ||
                          undefined,
                        value: options.domSelectors.images.heightParam.value,
                      }
                    : undefined,
                  widthParam: options.domSelectors.images.widthParam?.value
                    ? {
                        name:
                          options.domSelectors.images.widthParam.name ||
                          undefined,
                        value: options.domSelectors.images.widthParam.value,
                      }
                    : undefined,
                }
              : undefined,
          price: {
            dom: options.domSelectors.price.dom || undefined,
            normalize: options.domSelectors.price.normalize,
            divisor: options.domSelectors.price.divisor || undefined,
            roundingMethod:
              options.domSelectors.price.roundingMethod || undefined,
          },
          currency: options.domSelectors.currency?.dom
            ? options.domSelectors.currency
            : undefined,
          currencyCode: options.domSelectors.currencyCode?.dom
            ? options.domSelectors.currencyCode
            : undefined,
          shippingPrice:
            options.domSelectors.shippingPrice?.currency ||
            options.domSelectors.shippingPrice?.value
              ? {
                  currency:
                    options.domSelectors.shippingPrice.currency || undefined,
                  value: options.domSelectors.shippingPrice.value || undefined,
                }
              : undefined,
          title: options.domSelectors.title?.dom
            ? options.domSelectors.title
            : undefined,
          description: options.domSelectors.description?.dom
            ? options.domSelectors.description
            : undefined,
          availability: {
            isAmount: options.domSelectors.availability.isAmount,
            dom: options.domSelectors.availability.dom || undefined,
            inStockValue:
              options.domSelectors.availability.inStockValue || undefined,
          },
        }
      : undefined,
  };
};

export default normalizePlugin;
